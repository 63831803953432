import React, { useState, useEffect } from 'react';
import 'assets/styles/pages/sidebar.scss';
import { Link, navigate } from 'gatsby';
import treeicon from 'assets/images/admin/tree-icon.png';
import breadcumbarrow from 'assets/images/admin/breadcumb-arrow-left.png';
import { useForm } from "react-hook-form";
import AdminService from 'services/admin/admin.service';
import { Modal, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import PlantationListDetails from 'services/plantation/plantation.service';
import imageCompression from 'browser-image-compression';
import { compressOptions } from 'assets/data/imageCompress';
import closesmall from 'assets/images/icons/close-small-icon.svg';
// import Initiativeviewspecies from 'components/admin/initiative-view';

export default function Addspeciespage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const adminApi = new AdminService();
  const ds = new PlantationListDetails();
  const [loading, setLoading] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [signedList, setSignedList] = useState([]);
  const [fileError, setFileError] = useState([false]);
  const [pathImage, setPathImage] = useState([]);
  const [typeError, setTypeError] = useState(false);
  const [sizeError, setSizeError] = useState(false);

  const saveImg = async (data) => {
    setLoading(true)
    const plantationResponse = await ds.imagesSignedUrl({ signedUrlDetails: signedList });
    if (plantationResponse !== undefined) {
      if (plantationResponse.status === 200) {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error("Something went wrong");
    }
    await imageUploadCall(plantationResponse.data.signedUrlDetails, data);
  }

  const imageUploadCall = async (data, formValues) => {
    setLoading(true)
    let pathImages = []
    const resultsData = await data.map((item, index) => {

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/octet-stream");
      myHeaders.append("x-goog-acl", "public-read");

      let requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: filesList[index],
        redirect: 'follow'
      };
      setLoading(true)
      return fetch(item.signedUrl, requestOptions).then(result => {
        var obj = {};
        obj["pictureUrl"] = data[index].objectPath;
        pathImages.push(obj);
      })
    })
    return Promise.all(resultsData).then(() => {
      finalCall(pathImages, formValues)
    }).catch(e => {
      toast.error("Something went wrong")
      setLoading(false);
    })
  }
  const finalCall = (images, data) => {
    let formData = {
      "speciesName": data.speciesName,
      "scientificName": data.scientificName,
      "commonName": data.commonName,
      "naturalHabitat": data.naturalHabitat,
      "commonUses": data.commonUses,
      "status": data.planttype,
      "pictureDetails": images,
    }
    setLoading(true);
    adminApi.addAdminSpecies(formData).then(data => {
      if (data.status === 200) {
        setLoading(false)
        toast.success("Species added successfully")
        pathImage.length = 0;
        setSignedList([]);
        setTimeout(function () {
          navigate('/admin/speciesstatus/');
        }, 3000)
      }
    }).catch(error => {
      setLoading(false)
      pathImage.length = 0;
      setSignedList([]);
      toast.error(error.response.data.message);
    });
  }

  const fileLoad = async (e) => {
    let testFile = e.target.files[0]
    let fileExtension = testFile.type.split("/").pop();
    const compressedFile = await imageCompression(testFile, compressOptions);

    if (fileExtension !== "png" && fileExtension !== "jpg" && fileExtension !== "jpeg" && fileExtension !== "gif") {
      setTypeError(true)
    } else if (compressedFile.size > 2e+6) {
      setSizeError(true)
      setTypeError(false)
    } else {
      setLoading(true)
      const name = e.target.files[0].name;
      const lastDot = name.lastIndexOf('.');
      const fileName = name.substring(0, lastDot);
      const file = e.target.files[0];
      const filetype = e.target.files[0].type;
      let fileExtension = file.type.split("/").pop();
      let imgData = {
        "fileName": fileName,
        "fileExtension": fileExtension,
        "fileType": filetype
      }
      const filesData = [
        ...filesList,
        compressedFile,
      ]
      const signedData = [
        ...signedList,
        imgData,
      ]
      setFilesList(filesData)
      setSignedList(signedData)
      setFileError(false)
      setLoading(false)
    }
  }
  const onSubmit = async (data) => {
    if (signedList.length === 0) {
      setFileError(true)
    }
    if (fileError === false) {
      await saveImg(data);
    }
  };
  const imageRemove = (e) => {
    let myArray;
    let infoArray;
    myArray = filesList.filter(function (obj) {
      return obj.name !== e.currentTarget.id;
    });
    setFilesList(myArray)
    infoArray = signedList.filter(function (obj) {
      //  const fileVal = (obj.fileName).concat(obj.fileExtension)
      const lastDot = e.currentTarget.id.lastIndexOf('.');
      const fileName = e.currentTarget.id.substring(0, lastDot);
      return obj.fileName !== fileName;
    });
    setSignedList(infoArray)
  }
  return (
    <>
      <div className='sidebar-content-wrapper'>
        <div className='admin-breadcumb-item'>
          <span>
            <Link className='' to='/admin/dashboardview/'>   <img src={treeicon} alt='treeicon' /> </Link>
          </span>

          <span>
            <img src={breadcumbarrow} alt='arrowicon' />
          </span>
          <h3> Add new Species </h3>

          {/* <Link className='' to='/admin/speciesstatus/'>
            {' '}
            Back{' '}
          </Link> */}
        </div>
        <div className='white-iitiative-wrapper'>
          <div className=''>
            <h3 className='sub-heading-title'>ADD SPECIES </h3>
          </div>

          <div className='register-plantation'>
            <div className='container'>
              <div className='row'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='name' className='form-label'>
                          Species Name <i className="hastric-color">*</i>
                        </label>
                        <input
                          type='text'
                          name='speciesName'
                          className='form-control '
                          id='speciesName'
                          placeholder=''
                          {...register("speciesName", {
                            required: "Species Name is required"
                          })}
                        />
                        {errors.speciesName && (
                          <span className="form-error">
                            {errors.speciesName.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='date' className='form-label'>
                          Scientific Name <i className="hastric-color">*</i>
                        </label>
                        <input type='text' name='date' className='form-control ' id='date' placeholder=''
                          {...register("scientificName", {
                            required: "Scientific Name is required"
                          })} />
                        {errors.scientificName && (
                          <span className="form-error">
                            {errors.scientificName.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='date' className='form-label'>
                          Common Names <i className="hastric-color">*</i>
                        </label>
                        <input type='text' name='commonName' className='form-control ' id='date' placeholder=''
                          {...register("commonName", {
                            required: "Common Names is required"
                          })} />
                        {errors.commonName && (
                          <span className="form-error">
                            {errors.commonName.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='date' className='form-label'>
                          Natural Habitat <i className="hastric-color">*</i>
                        </label>
                        <input type='text' name='naturalHabitat' className='form-control ' id='date' placeholder=''
                          {...register("naturalHabitat", {
                            required: "Natural Habitat is required"
                          })} />
                        {errors.naturalHabitat && (
                          <span className="form-error">
                            {errors.naturalHabitat.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className='col-md-12'>
                      <div className='fc-wrapper'>
                        <label htmlFor='name' className='form-label'>
                          Common Uses <i className="hastric-color">*</i>
                        </label>
                        <textarea
                          type='textarea'
                          name='commonUses'
                          className='form-control '
                          id='commonUses'
                          placeholder=''
                          {...register("commonUses", {
                            required: "Common Uses is required"
                          })}
                        />
                        {errors.commonUses && (
                          <span className="form-error">
                            {errors.commonUses.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='planttype' className='form-label'>
                          Status <i className="hastric-color">*</i>
                        </label>
                        <select name='planttype' id='planttype' className='form-control' {...register("planttype", { required: true })}>
                          <option value='2'>Active</option>
                          <option value='1'> Inactive </option>
                        </select>
                      </div>
                    </div>

                    <div className='col-md-12 mg-top30'>
                      <h3 className='sub-heading-title'> ADD IMAGES </h3>

                      <div className='dis-flex cent-item-mob add-more-img-posi'>
                        {filesList && filesList.map(data => {
                          return (<div className='img_wrp'>
                            <span className='small-close small-close-images hand-pointer close' >
                              <div id={data.name} onClick={imageRemove}> <img src={closesmall} id="closeIcon" alt='Close small' />{' '}</div>
                            </span>
                            <img src={URL.createObjectURL(data)} className='img-fluid' alt='Add image List' width="140px" height="70px" />
                          </div>)

                        })}

                        <div className='border-items margin-left-11'>
                          <span className='plus-icon-green btn-file'>
                            <i className='icon icon-plus-lg plus-symbol-custom-icon'></i>
                            <input type="file" onChange={fileLoad} accept="image/png, image/gif, image/jpeg" />Add more
                          </span>
                          {/* <p><input type="file" onChange={fileLoad} /> Add more</p> */}
                        </div>
                      </div>
                    </div>
                    <div> <br />
                      {sizeError === true ?
                        <span className="form-error">
                          Please upload a file smaller than 2 MB
                        </span>
                        : null}
                      {typeError === true ?
                        <span className="form-error">
                          File does not support. You must use .png or .jpg
                        </span>
                        : null}
                      {fileError === true ? <span className="form-error">Add at least one image</span> : null}
                    </div>
                  </div>
                  <div className='text-right'>
                    <Link to='/admin/speciesstatus/' className='btn btn-cancel mg-right10 margin-btm-10'>
                      Cancel
                    </Link>

                    <button className='btn btn-viewmore save-width'>
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />

    </>
  );
}
