import React from 'react';

import AdminLayout from '../../adminlayout/index';

import SEO from 'components/seo/index';
// import Side from 'layout/sidebaritem';

import Addspeciespage from 'components/admin/addspeciespage';

const Addspecies = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness admin' />
      <AdminLayout>
        <div>
          <Addspeciespage />
        </div>
      </AdminLayout>
    </>
  );
};

export default Addspecies;
